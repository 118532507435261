import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from "../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import { withUserContext } from "../../../contexts/UserContext";
import { CircularProgress } from "@material-ui/core";
import { Popover } from "@material-ui/core";
import "./NewCrew.scss";
import CrewModal from "../../components/Crew/CrewContainer";
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import NotificationComponent from "../../Common/NotificationComponent/NotificationComponent";
import { initialNewCrewDetails, initialEmailObjectConfig, initialPhoneObjectConfig } from "./Config";
import ModalComponent from "../../Common/DefaultModalLayout/Modal/Modal";
import PhoneComponent from "../../Common/DefaultPhoneComponent/PhoneComponent";
import { validatePlusCharacter, validateEmail, validateAllPhone } from "../../Common/Helper";
import InputSelectRadio from "../../Common/InputSelectRadioInline/InputSelectRadio";
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import axios from 'axios';

class NewCrewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newCrewDetails: initialNewCrewDetails,
      isSubmit: false,
      isSaveEdit: false,
      showError: false,
      showSuccess: false,
      showFail: false,
      saveFlag: false,
      disablePhoneSave: false,
      disableEmailSave: false
    };
  }
  getLookupValues(type) {
    // casterFeatureLookup
    CasterService.getData(
      Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`
    ).then(
      (response) => {
        if (!response || response?.data?.error) {
          console.log("error", response?.data?.error);
        } else {
          let formattedList = response?.data?.map((item) => ({
            id: item?.lookup_data_id,
            value: item?.lookup_value,
            label: item?.lookup_value,
          }));
          if (type == "Email") {
            this.setState({ emailOptions: formattedList });
          } else if (type == "Phone") {
            this.setState({ phoneOptions: formattedList });
          }
        }
      },
      (err) => {
        console.log("error from lookup");
      }
    );
  }
  componentDidMount() {
    if (this.props?.crewData && this.props?.crewData?.value) {
      this.getLookupValues("Phone");
      this.getLookupValues("Email");
      console.log("this.props?.crewData componentDidMount", this.props?.crewData);
      let newValue = this.props?.crewData?.value?.split(" ");
      let first_value = newValue[0];
      let middle_value = newValue[1];
      newValue.splice(0, 2);
      this.handleOnchange('first_name', first_value);
      this.handleOnchange('middle_name', middle_value);
      this.handleOnchange('last_name', newValue?.length > 1 ? newValue?.join(" ").toString() : newValue?.toString());
    }
    // this.handleOnchange("first_name", this.props?.crewData?.value);
  }
  handleResetNotify = () => {
    this.setState({ showFail: false, showSuccess: false });
    if (this.state.saveFlag) {
      this.setState({ saveFlag: false });
    }
  };

  handleOnchange = (field, value) => {
    this.setState((prevState) => ({
      newCrewDetails: {
        ...prevState.newCrewDetails,
        [field]: value,
      },
    }));
  };
  validateBeforePost(postJson) {
    let isValid = false;
    isValid = postJson?.first_name && postJson?.last_name;
    return isValid;
  }
  notificationComponent = (value, mode = "fail") => {
    switch (mode) {
      case "success":
        return this.setState({ showSuccess: value, saveFlag: true });
      case "fail":
        return this.setState({ showFail: value });
    }
  };

  formatCrewDetails = (postJson) => {
    postJson.email_list = postJson.email_list?.slice(1) || [];
    postJson.phone_list = postJson.phone_list?.slice(1) || [];
    if (postJson.email_list?.length > 0) {
      postJson.email_list = postJson.email_list?.reduce((acc, i) => {
        i.object_id = i?.type == 'new' ? null : i?.object_id
        if (i?.object_id || (!i?.object_id && !i?.is_delete))
          acc = [...acc, {
            project_crew_email_id: i?.object_id,
            object_id: i?.object_id,
            email_type: i?.object_type,
            object_type: i?.object_type,
            email: i?.value,
            value: i?.value,
            is_delete: i?.is_delete || 0,
            is_primary: i?.is_primary
          }]
        return acc;
      }, [])
    }
    if (postJson.phone_list?.length > 0) {
      postJson.phone_list = postJson.phone_list?.reduce((acc, i) => {
        i.object_id = i?.type == 'new' ? null : i?.object_id
        if (i?.object_id || (!i?.object_id && !i?.is_delete))
          acc = [...acc, {
            project_crew_phone_id: i?.object_id,
            object_id: i?.object_id,
            phone_type: i?.object_type,
            object_type: i?.object_type,
            phone: i?.value,
            is_delete: i?.is_delete || 0,
            is_primary: i?.is_primary,
            ext: i?.ext ? parseInt(i?.ext) : null
          }]
        return acc;
      }, [])
    }
    return postJson;
  }

  handleSubmit = (flag) => {
    // let postJson = { ...this.state.newCrewDetails };
    let postJson = this.formatCrewDetails(JSON.parse(JSON.stringify({ ...this.state.newCrewDetails })));
    this.setState({
      isSubmit: flag === "is_save" ? true : false,
      isSaveEdit: flag === "is_save_edit" ? true : false,
    });
    if (!this.validateBeforePost(postJson)) {
      this.setState({ isSubmit: false, isSaveEdit: false, showError: true });
      return;
    }
    CasterService.postDataParams(
      Constants.CasterServiceBaseUrl + "/casterFeatureCrewDetails",
      postJson,
      this.state?.selecttenant
    ).then(
      (response) => {
        if (response?.data?.error) {
          this.setState({ isSubmit: false, isSaveEdit: false, showError: false });
          this.notificationComponent(true, "fail");
        } else {
          this.setState({ isSubmit: false, isSaveEdit: false, showError: false },()=>{
          //this.notificationComponent(true, "success");
          //setTimeout(() => {
            if (flag === "is_save_edit") {
              this.props?.setObjectSelected('crew',
                {
                  text: `${postJson?.first_name ? postJson?.first_name + ' ' : ''}${postJson?.middle_name ? postJson?.middle_name + ' ' : ''}${postJson?.last_name ? postJson?.last_name + ' ' : ''}${postJson?.suffix ? postJson?.suffix : ''}`,
                  value: response?.data?.[0]?.id,
                  aka: `${postJson?.first_name ? postJson?.first_name + ' ' : ''}${postJson?.middle_name ? postJson?.middle_name + ' ' : ''}${postJson?.last_name ? postJson?.last_name + ' ' : ''}${postJson?.suffix ? postJson?.suffix : ''}`,
                  headerName: `${postJson?.first_name ? postJson?.first_name + ' ' : ''}${postJson?.middle_name ? postJson?.middle_name + ' ' : ''}${postJson?.last_name ? postJson?.last_name + ' ' : ''}${postJson?.suffix ? postJson?.suffix : ''}`,
                })
              this.props?.handleClose("fromNewEditCrewAssistant");
            } else {
              const newCrew = 1;
              this.props?.setNewObjectSelected('crew',
                {
                  text: `${postJson?.first_name ? postJson?.first_name + ' ' : ''}${postJson?.middle_name ? postJson?.middle_name + ' ' : ''}${postJson?.last_name ? postJson?.last_name + ' ' : ''}${postJson?.suffix ? postJson?.suffix : ''}`,
                  optionType: 'save',
                  value: response?.data?.[0]?.id,
                  is_delete: 0
                }, newCrew)
              this.props?.handleClose("fromNewEditCrewAssistant");
            }
          //}, 1500)
        });
        }
      },
      (err) => {
        this.setState({ isSubmit: false, isSaveEdit: false, showError: false });
        this.notificationComponent(true, "fail");
      }
    );
  };
  handleGeneralEdit = (field, value) => {
    let newValue = value;
    if (typeof value === "boolean") {
      newValue = value ? 1 : 0;
    }
    this.setState(
      (prevState) => ({
        newCrewDetails: {
          ...prevState.newCrewDetails,
          [field]: newValue,
        },
        postInitiated: false,
      })
    );
  };
  addRemoveObject(list, value) {
    let objectList = [...this.state?.newCrewDetails?.[list]];
    objectList = objectList?.filter((i, index) => index != 0);

    let initObjectConfig;
    if (list === "email_list") {
      initObjectConfig = JSON.parse(JSON.stringify(initialEmailObjectConfig));
    }
    if (list === "phone_list") {
      initObjectConfig = JSON.parse(JSON.stringify(initialPhoneObjectConfig));
    }
    value.type = 'new'
    value.object_id = Date.now();
    let objList = objectList?.filter((i, index) => i.is_delete != 1);
    value.is_primary = objList?.length == 0 ? 1 : 0
    objectList = [initObjectConfig, ...objectList, value]

    this.handleGeneralEdit(list, objectList)
  }

  onChangeObject(list, id, key, value) {
    let objectList = JSON.parse(JSON.stringify(this.state.newCrewDetails?.[list]));
    let objectIndex = id ? objectList?.findIndex(i => i.object_id == id) : 0;
    if (key == 'is_primary') {
      objectList?.map(i => i.is_primary = 0);
      value = 1;
    }
    if (key == "is_delete") {
      value = 1;
    }
    if (list === "phone_list" && key === "object_type") {
      objectList[objectIndex]["phone_type"] = value;
    }
    if (list === "phone_list" && key === "value") {
      objectList[objectIndex]["phone"] = value;
    }
    if (list === "email_list" && key === "object_type") {
      objectList[objectIndex]["email_type"] = value;
    }
    if (list === "email_list" && key === "value") {
      objectList[objectIndex]["email"] = value;
    }
    objectList[objectIndex][key] = value;
    this.handleGeneralEdit(list, objectList)
  }
  handleSelectedOccupation = (newValue) => {
    if (newValue) {
      let selectedOccupationData = this.state.occupationOptions?.find(item => item.value === newValue.value);
      this.setState({ selectedOccupation: newValue, selectedOccupationData: selectedOccupationData })
      this.setState(
        (prevState) => ({
          newCrewDetails: {
            ...prevState.newCrewDetails,
            ["occupation"]: newValue?.text,
            ["occupation_id"]: newValue?.value,
          },
          postInitiated: false,
        })
      );
    }
    else {
      this.setState({ selectedRep: null })
    }
  }
  getObjectDetails(objectType, searchString = null) {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    this.setState({ [`is${objectType}Fetch`]: true })
    CasterService.getDataWithCancel(
      Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`, cancelToken)
      .then((response) => {
        this.setState({ [`is${objectType}Fetch`]: false })
        if (response?.data?.error) {
          console.log('error from search', repsonse?.data?.error)
        } else {
          let formattedList;
          if (objectType == "Occupation") {
            formattedList = response?.data?.map(i => ({
              value: i?.lookup_data_id,
              text: i?.lookup_value
            }))
            this.setState({ occupationOptions: formattedList })
          }
          console.log("post response", response);
        }
      },
        (err) => {
          this.setState({ [`is${objectType}Fetch`]: false })
          console.log("Post object details error: " + err);
        });
  }
  handleClose = () => {
    this.props?.handleClose();
  }

  render() {
    let severity = this.state.showFail
      ? "error"
      : this.state.showSuccess
        ? "success"
        : "";
    let messageNotification = this.state.showSuccess
      ? "The changes are saved successfully!"
      : this.state.showFail
        ? "Saving changes failed!"
        : "";
      let childContainer = <div className="NewCrewModalContainer caster-feature">
      {this.props?.isDashboard ? (
        <MDBRow>
          <MDBCol md={12}>
            <div>New Crew will be displayed in application as:</div>
          </MDBCol>
        </MDBRow>
      ) : null}
      <>
        <MDBRow className='bottomMargin'>
          <MDBCol md={3}>
            <BasicTextField
              label={"First Name"}
              value={this.state.newCrewDetails.first_name}
              // placeholder="First Name (required)"
              isMandatory={true}
              showMandatory={this.state?.showError}
              onChange={(e) =>
                this.handleOnchange("first_name", e.target.value)
              }
              handleAlphabetVariantSelect={(value) => this.handleOnchange("first_name", value)}
              isAlphabetVar={true}
            />
          </MDBCol>
          <MDBCol md={3}>
            <BasicTextField
              label={"Middle Name"}
              // placeholder="Middle Name"
              value={this.state.newCrewDetails.middle_name}
              onChange={(e) =>
                this.handleOnchange("middle_name", e.target.value)
              }
              handleAlphabetVariantSelect={(value) => this.handleOnchange("middle_name", value)}
              isAlphabetVar={true}
            />
          </MDBCol>
          <MDBCol md={3}>
            <BasicTextField
              label={"Last Name"}
              // placeholder="Last Name (required)"
              isMandatory={true}
              showMandatory={this.state?.showError}
              value={this.state.newCrewDetails.last_name}
              onChange={(e) =>
                this.handleOnchange("last_name", e.target.value)
              }
              handleAlphabetVariantSelect={(value) => this.handleOnchange("last_name", value)}
              isAlphabetVar={true}
            />
          </MDBCol>
          <MDBCol md={3}>
            <BasicTextField
              label={"Suffix"}
              isAlphabetVar={true}
              // placeholder="Suffix"
              value={this.state.newCrewDetails.suffix}
              onChange={(e) =>
                this.handleOnchange("suffix", e.target.value)
              }
              handleAlphabetVariantSelect={(value) => this.handleOnchange("suffix", value)}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow className='bottomMargin'>
          <MDBCol md={12}>
            <SearchSelectField
              label="Occupation"
              id={"occupationFieldProjectNew"}
              options={this.state?.occupationOptions || []}
              //value={this.state?.newCrewDetails?.occupation ? this.state?.newCrewDetails?.occupation : null}
              width={'100%'}
              detail_selected={this.state?.selectedOccupation}
              valueSelected={(e, value) => this.handleSelectedOccupation(value)}
              searchText={(e) => this.getObjectDetails("Occupation", e.target.value)}
              multiple={false}
              searchSelect={true}
              placeholder={"- Search Occupation -"}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow className='bottomMargin'>
          <MDBCol md={12}>
            <BasicTextField
              label="Location"
              value={this.state?.newCrewDetails?.location_name}
              onChange={(e) => this.handleOnchange('location_name', e.target?.value)}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow className='bottomMargin'>
          <MDBCol md={12}>
            <BasicTextField
              label="Company"
              value={this.state?.newCrewDetails?.company}
              onChange={(e) => this.handleOnchange('company', e.target?.value)}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md={5}>
            {/* {!this.props?.isEditing ? <BasicLabel text="Phone" /> : null} */}
            {this.state?.newCrewDetails?.phone_list?.filter(i => i?.is_delete == 0)?.map((phone, index) => (
              <MDBRow>
                <MDBCol md={12}>
                  <PhoneComponent
                    radioGroupName='crewPhoneRadio'
                    showAsLabel={false}
                    isEditing={true}
                    objectItem={phone}
                    ext={true}
                    placeholder={" "}
                    inValidInput={"Please enter valid phone"}
                    addCallback={(obj) => this.addRemoveObject('phone_list', obj)}
                    removeCallback={(id, key, value) => this.onChangeObject('phone_list', id, key, value)}
                    editCallback={(id, key, value) => this.onChangeObject('phone_list', id, key, value)}
                    selectOptions={this.state?.phoneOptions}
                    showAdd={(index === 0) ? true : false}
                    showRemove={index != 0 ? true : false}
                    showRadio={index != 0 ? true : false}
                    inputValidateMethod={validateAllPhone}
                    showLabel={index == 0 ? true : false}
                    labelNew={"Phone"}
                    addButton={index == 0 ? true : false}
                    originPoint={"newCrew"}
                    className="posRelative"
                    enableSecondaryError={true}
                  />
                </MDBCol>
                {/* <MDBCol md={1}>
                    <div>
                      {phone?.is_primary && !this.props?.isEditing ? <MDBIcon fas icon="star" /> : null }
                    </div>
                  </MDBCol> */}
              </MDBRow>
            ))}
          </MDBCol>
          <MDBCol md={7}>
            {this.state?.newCrewDetails?.email_list
              ?.filter((i) => i?.is_delete == 0)
              ?.map((item, index) => (
                <InputSelectRadio
                  className="posRelative"
                  radioGroupName="crewEmailRadio"
                  inputPlaceholder=" "
                  showAsLabel={false}
                  isEditing={true}
                  objectItem={item}
                  addCallback={(emailObj) =>
                    this.addRemoveObject("email_list", emailObj)
                  }
                  removeCallback={(id, key, value) =>
                    this.onChangeObject(
                      "email_list",
                      id,
                      key,
                      value
                    )
                  }
                  editCallback={(id, key, value) =>
                    this.onChangeObject(
                      "email_list",
                      id,
                      key,
                      value
                    )
                  }
                  selectOptions={this.state.emailOptions}
                  showAdd={(index === 0) ? true : false}
                  showRemove={
                    index != 0 ? true : false
                  }
                  showRadio={
                    index != 0 ? true : false
                  }
                  inputValidateMethod={validateEmail}
                  showLabel={index == 0 ? true : false}
                  labelNew={"Email"}
                  addButton={index == 0 ? true : false}
                  enableSecondaryError={true}
                />
              ))}
          </MDBCol>
        </MDBRow>
      </>
      <MDBRow className="mt-3">
        <MDBCol md={5} lg={5}></MDBCol>
        <MDBCol md="3" lg="3" className='d-flex justify-content-end'>
          <BasicButton
            className="btnwidth"
            variant="contained"
            text={
              this.state.isSaveEdit ? (
                <CircularProgress color="inherit" size={18} />
              ) : (
                  "Save & Edit"
                )
            }
            // disabled={this.state.disablePhoneSave || this.state.disableEmailSave}
            onClick={() => this.handleSubmit("is_save_edit")}
          />
        </MDBCol>
        <MDBCol md="2" lg="2" className='d-flex justify-content-end'>
          <BasicButton
            className="btnwidth"
            variant="contained"
            text={
              this.state.isSubmit ? (
                <CircularProgress color="inherit" size={18} />
              ) : (
                  "Save"
                )
            }
            icon={"save"}
            // disabled={this.state.disablePhoneSave || this.state.disableEmailSave}         
            onClick={() => this.handleSubmit("is_save")}
          />
        </MDBCol>
        <MDBCol md="2" lg="2" className='d-flex justify-content-end'>
          <BasicButton
            variant="outlined"
            text=" "
            icon={"times"}
            onClick={this.handleClose}
          />
        </MDBCol>
      </MDBRow>

    </div>
    
    return (
      <div disableEnforceFocus>
        <NotificationComponent
          open={this.state.showSuccess || this.state.showFail}
          message={messageNotification}
          severity={severity}
          handleResetNotify={this.handleResetNotify.bind(this)}
        />
        {this.props?.id==="newCrewFromadvancesea"?
        <Popover
          open={this.props.open}
          anchorEl={this.props?.setPopOver}
          onClose={() => {
            this.props?.handleClose();
          }}
          className="talentPopver"
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{ style: { width: "50%", backgroundColor: "#F2F2F2" } }}
        > 
        {childContainer}
        </Popover>
        :childContainer}
      </div>
    );
  }
}
export default withUserContext(NewCrewModal);