import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import SearchFieldWithAddValue from '../SharedComponents/SearchFieldWithAddValue'
import { CircularProgress } from "@material-ui/core";
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import './ProjectNewCrew.scss'
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import NewCrewModal from '../../Common/NewCrew/NewCrew';
import { crewNavList, initialEmailObjectConfig, initialPhoneObjectConfig } from '../Crew/Config';
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal';
import axios from 'axios';
import PhoneComponent from "../../Common/DefaultPhoneComponent/PhoneComponent";
import { validatePlusCharacter, validateEmail, validateAllPhone } from "../../Common/Helper";
import InputSelectRadio from "../../Common/InputSelectRadioInline/InputSelectRadio";
import SearchSelectField from '../SharedComponents/SearchSelectField';

export default class ProjectNewCrew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCrewFetch: false,
      isOccupationFetch: false,
      isCrewDetailsFetching: false,
      selectedCrew: null,
      showCrewModal: false,
      crewOptions: [],
      selectedOccupation: null,
      occupationOptions: [],
      openCrewPopOver: null,
      setCrewPopOver: null,
      isPosting: false,
      crewDetails: null,
      showError: false,
      searchCancelToken: null,
      emailOptions: [],
      phoneOptions: [],
      selectedOccupationData: null,
      selectedCrewNew: null,
      isNewCrew: 0,
      disablePhoneSave:false,
      disableEmailSave:false
    }
  }
  componentDidMount() {
    this.getLookupValues("Phone");
    this.getLookupValues("Email");
    if (this.props?.mode == 'edit' && this.props?.crewSelected) {
      this.setState({ selectedCrew: this.props?.crewSelected }, () => this.getEditCrewDetails())

    }
  }
  getObjectDetails(objectType, searchString = null) {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    this.setState({ [`is${objectType}Fetch`]: true })
    CasterService.getDataWithCancel(
      Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`, cancelToken)
      .then((response) => {
        this.setState({ [`is${objectType}Fetch`]: false })
        if (response?.data?.error) {
          console.log('error from search', repsonse?.data?.error)
        } else {
          let formattedList;
          if (objectType == "Crew") {
            formattedList = response?.data?.map(i => ({
              value: i?.id,
              text: i?.name,
              aka: i?.aka,
              headerName: i?.crew_name
            }))
            this.setState({ crewOptions: formattedList })
          }
          if (objectType == "Occupation") {
            formattedList = response?.data?.map(i => ({
              value: i?.lookup_data_id,
              text: i?.lookup_value
            }))
            this.setState({ occupationOptions: formattedList })
          }
        }
      },
        (err) => {
          this.setState({ [`is${objectType}Fetch`]: false })
          console.log("Post object details error: " + err);
        });
  }
  getEditCrewDetails() {
    if (this.props?.id) {
      this.setState({ isCrewDetailsFetching: true })
      CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureProjectCrew?project_id=${this.props?.project_id}&project_crew_id=${this.props?.id}`)
        .then((response) => {
          this.setState({ isCrewDetailsFetching: false })
          if (response?.data?.error) {
            console.log('error from search', repsonse?.data?.error)
          } else {
            let crewDetails = {};
            if (response?.data?.length > 0) {
              crewDetails = { ...response?.data?.[0] };
              if (crewDetails?.occupation && crewDetails?.occupation_id) {
                let selectedOccupation = { value: crewDetails?.occupation_id, text: crewDetails?.occupation };
                this.setState({ selectedOccupation });
              }
              let emailObjectConfig = JSON.parse(
                JSON.stringify(initialEmailObjectConfig)
              );
              let phoneObjectConfig = JSON.parse(
                JSON.stringify(initialPhoneObjectConfig)
              );
              if (crewDetails?.phone_list?.length > 0) {
                let formattedObj = crewDetails?.phone_list?.map((i) => {
                  return {
                    project_crew_phone_id: i?.project_crew_phone_id,
                    object_id: i?.project_crew_phone_id,
                    phone_type: i?.phone_type,
                    object_type: i?.phone_type,
                    phone: i?.phone,
                    value: i?.phone,
                    is_primary: i?.is_primary,
                    is_delete: i?.is_delete,
                    ext: i?.ext,
                  };
                });
                crewDetails.phone_list = [phoneObjectConfig, ...formattedObj];
              } else {
                crewDetails.phone_list = [];
                crewDetails.phone_list.push(phoneObjectConfig);
              }
              if (crewDetails.email_list?.length > 0) {
                let formattedObj = crewDetails?.email_list?.map((i) => {
                  return {
                    project_crew_email_id: i?.project_crew_email_id,
                    object_id: i?.project_crew_email_id,
                    email_type: i?.email_type,
                    object_type: i?.email_type,
                    email: i?.email,
                    value: i?.email,
                    is_primary: i?.is_primary,
                    is_delete: i?.is_delete,
                  };
                });
                crewDetails.email_list = [emailObjectConfig, ...formattedObj];
              } else {
                crewDetails.email_list.push(emailObjectConfig);
              }
            }
            this.setState({ crewDetails: crewDetails })
          }
        },
          (err) => {
            this.setState({ isCrewDetailsFetching: false })
            console.log("Post object details error: " + err);
          });
    }
  }
  getCrewDetails = (origin=null) => {
    if (this.state?.selectedCrew?.value) {
      this.setState({ isCrewDetailsFetching: true })
      CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureCrewDetails?id=${this.state?.selectedCrew?.value}`)
        .then((response) => {
          this.setState({ isCrewDetailsFetching: false })
          if (response?.data?.error) {
            console.log('error from search', repsonse?.data?.error)
          } else {
            let crewDetails = response?.data?.length > 0 ? { ...response?.data?.[0] } : {};
            //crewDetails.location_name = crewDetails?.location;
            if (crewDetails?.occupation && crewDetails?.occupation_id) {
              let selectedOccupation = { value: crewDetails?.occupation_id, text: crewDetails?.occupation };
              this.setState({ selectedOccupation });
            }
            let emailObjectConfig = JSON.parse(
              JSON.stringify(initialEmailObjectConfig)
            );
            let phoneObjectConfig = JSON.parse(
              JSON.stringify(initialPhoneObjectConfig)
            );
            if (crewDetails?.phone_list?.length > 0) {
              let formattedObj = crewDetails?.phone_list?.map((i) => {
                return {
                  project_crew_phone_id: i?.project_crew_phone_id,
                  object_id: i?.project_crew_phone_id,
                  phone_type: i?.phone_type,
                  object_type: i?.phone_type,
                  phone: i?.phone,
                  value: i?.phone,
                  is_primary: i?.is_primary,
                  is_delete: i?.is_delete,
                  ext: i?.ext,
                };
              });
              crewDetails.phone_list = [phoneObjectConfig, ...formattedObj];
            } else {
              crewDetails.phone_list = [];
              crewDetails.phone_list.push(phoneObjectConfig);
            }
            if (crewDetails.email_list?.length > 0) {
              let formattedObj = crewDetails?.email_list?.map((i) => {
                return {
                  project_crew_email_id: i?.project_crew_email_id,
                  object_id: i?.project_crew_email_id,
                  email_type: i?.email_type,
                  object_type: i?.email_type,
                  email: i?.email,
                  value: i?.email,
                  is_primary: i?.is_primary,
                  is_delete: i?.is_delete,
                };
              });
              crewDetails.email_list = [emailObjectConfig, ...formattedObj];
            } else {
              crewDetails.email_list = [];
              crewDetails.email_list.push(emailObjectConfig);
            }
            this.setState({ crewDetails: crewDetails },()=>{
              if(this.state.isNewCrew===1 || origin==="calledfromClose"){
                this.handleSubmit();
                this.handleCloseCrewPopOver();
             }
              
            });
          }
        },
          (err) => {
            this.setState({ isCrewDetailsFetching: false })
            console.log("Post object details error: " + err);
          });
    }
  }
  onChange(type, value, e) {
    if (value) {
      if (value?.optionType === 'new') {
        this.setState({ setCrewPopOver: true, openCrewPopOver: true, selectedCrew: value, selectedCrewNew: value })
      }
      else if (value?.optionType === 'edit') {
        this.setState({ selectedCrew: value, showCrewModal: true, selectedCrewNew: null, setCrewPopOver:null,openCrewPopOver: null })
      }
      else {
        if (type === "crew") {
          this.setState({ selectedCrew: value, crewOptions: [],openCrewPopOver: null }, () => {
            this.getCrewDetails();
          })
        }
        if (type === "occupation") {
          this.setState({ selectedOccupation: value, occupationOptions: [] })
        }
      }
    } else {
      this.setState({ selectedCrew: null, crewOptions: [], crewDetails: null, selectedOccupation: null, occupationOptions: [] })
    }
  }
  onChangeField(field, value) {
    if (!value) {
      value = null
    }
    this.setState(prevState => ({
      crewDetails: {
        ...prevState?.crewDetails,
        [field]: value
      }
    }))
  }

  formatCrewDetails = (postJson) => {
    postJson.email_list = postJson.email_list?.slice(1) || [];
    postJson.phone_list = postJson.phone_list?.slice(1) || [];
    if (postJson.email_list?.length > 0) {
      postJson.email_list = postJson.email_list?.reduce((acc, i) => {
        i.object_id = i?.type == 'new' ? null : i?.object_id
        if (i?.object_id || (!i?.object_id && !i?.is_delete))
          acc = [...acc, {           
            project_crew_email_id: i?.object_id,
            object_id: i?.object_id,
            email_type: i?.object_type,
            object_type :i?.object_type,
            email: i?.value,
            value: i?.value,
            is_delete: i?.is_delete || 0,
            is_primary: i?.is_primary
          }]
        return acc;
      }, [])
    }
    if (postJson.phone_list?.length > 0) {
      postJson.phone_list = postJson.phone_list?.reduce((acc, i) => {
        i.object_id = i?.type == 'new' ? null : i?.object_id
        if (i?.object_id || (!i?.object_id && !i?.is_delete))
          acc = [...acc, {
            project_crew_phone_id: i?.object_id,         
            object_id:  i?.object_id,
            phone_type: i?.object_type,
            object_type: i?.object_type,
            phone: i?.value,
            is_delete: i?.is_delete || 0,
            is_primary: i?.is_primary,
            ext: i?.ext ? parseInt(i?.ext) : null
          }]
        return acc;
      }, [])
    }
 
   
    return postJson;
  }
  

  handleSubmit() {
    let postJson = this.formatCrewDetails(JSON.parse(JSON.stringify({...this.state.crewDetails})));

    delete postJson?.address_list;
    delete postJson?.akas;
    delete postJson?.assistant_list;
    delete postJson?.social_list;
    postJson.project_id = this.props?.project_id;
    if (postJson.phone_list?.length === 1) {
      postJson.phone_list[0].is_primary = 1;
    }
    if (postJson.email_list?.length === 1) {
      postJson.email_list[0].is_primary = 1;
    }
    postJson.project_crew_id = this.props?.mode == 'new' ? null : this.props?.id;
    if (!postJson?.crew_id) {
      this.setState({ showError: true })
      return;
    }
    this.setState({ isPosting: true, showError: false })
    CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeatureProjectCrew`, postJson)
      .then((response) => {       
        if (response?.data?.error) {
          console.log('project crew submit', response?.data?.error)
          this.props?.notificationComponent(true, "fail");
        } else {
          this.props?.toggleRefreshPage('ProjectCrew')
          this.props?.notificationComponent(true, "success");
          this.setState({ isPosting: false,isNewCrew:0 });
          this.props?.onClose();
        }
      },
        (err) => {
          this.setState({ isPosting: false,isNewCrew:0 })
          this.props?.notificationComponent(true, "fail");
          console.log("Post object details error: " + err);
        });

  }
  setObjectSelected(type, value) {
    value.optionType = 'edit'
    this.onChange(type, value)

  }

  setNewObjectSelected(type, value,isNewCrew = 0) {
    this.setState({isNewCrew: isNewCrew});
    value.optionType = 'save';
    this.onChange(type, value);
  }
  handleCloseCrewPopOver = () => {
    this.setState({ setCrewPopOver: null, openCrewPopOver: false })
  }
  handleGeneralEdit = (field, value) => {
    let newValue = value;
    if (typeof value === "boolean") {
      newValue = value ? 1 : 0;
    }
    this.setState(
      (prevState) => ({
        crewDetails: {
          ...prevState.crewDetails,
          [field]: newValue,
        },
        postInitiated: false,
      })
    );
  };
  addRemoveObject(list, value) {
    let objectList = [...this.state?.crewDetails?.[list]];
    objectList = objectList?.filter((i, index) => index != 0);
    let initObjectConfig;
    if (list === "email_list") {
      initObjectConfig = JSON.parse(JSON.stringify(initialEmailObjectConfig));
    }
    if (list === "phone_list") {
      initObjectConfig = JSON.parse(JSON.stringify(initialPhoneObjectConfig));
    }
    value.type = 'new'
    value.object_id = Date.now();
    let objList = objectList?.filter((i, index) => i.is_delete != 1);
    value.is_primary = objList?.length == 0 ? 1 : 0
    objectList = [initObjectConfig, ...objectList, value]

    this.handleGeneralEdit(list, objectList)
  }

  onChangeObject(list, id, key, value) {
    let objectList = [...this.state?.crewDetails?.[list]];
    let objectIndex = id ? objectList?.findIndex(i => i.object_id == id) : 0;
    if (key == 'is_primary') {
      objectList?.map(i => i.is_primary = 0);
      value = 1;
    }
    if (key == "is_delete") {
      value = 1;
    }
    if (list === "phone_list" && key === "object_type") {
      objectList[objectIndex]["phone_type"] = value;
    }
    if (list === "phone_list" && key === "value") {
      objectList[objectIndex]["phone"] = value;
    }
    if (list === "email_list" && key === "object_type") {
      objectList[objectIndex]["email_type"] = value;
    }
    if (list === "email_list" && key === "value") {
      objectList[objectIndex]["email"] = value;
    }
    objectList[objectIndex][key] = value;
    this.handleGeneralEdit(list, objectList);
  }
  getLookupValues(type) {
    // casterFeatureLookup
    CasterService.getData(
      Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`
    ).then(
      (response) => {
        if (!response || response?.data?.error) {
          console.log("error", response?.data?.error);
        } else {
          let formattedList = response?.data?.map((item) => ({
            id: item?.lookup_data_id,
            value: item?.lookup_value,
            label: item?.lookup_value,
          }));
          if (type == "Email") {
            this.setState({ emailOptions: formattedList });
          } else if (type == "Phone") {
            this.setState({ phoneOptions: formattedList });
          }
        }
      },
      (err) => {
        console.log("error from lookup");
      }
    );
  }
  handleSelectedOccupation = (newValue) => {
    if (newValue) {
      let selectedOccupationData = this.state.occupationOptions?.find(item => item.value === newValue.value);
      this.setState({ selectedOccupation: newValue, selectedOccupationData: selectedOccupationData })
      this.setState(
        (prevState) => ({
          crewDetails: {
            ...prevState.crewDetails,
            ["occupation"]: newValue?.text,
            ["occupation_id"]: newValue?.value,
          },
          postInitiated: false,
        })
      );
    }
    else {
      this.setState({ selectedRep: null })
    }
  }

  ValidateSaveButton(field,value) {
    field==='phone' && this.setState({ disablePhoneSave: value })
    field==='email' && this.setState({ disableEmailSave: value })
  }
  render() {
    return (
      <MDBContainer fluid className={`ProjectNewCrewContainer ${this.state?.selectedCrew ? 'selectedCrewPopup ':' '} ${this.props?.mode == 'edit' ? 'editCrewPopup ' :' '} ${ this.state.openCrewPopOver ? 'openCrewPopup':'noOpenCrewPopup'}`}>
         {this.state.isNewCrew ===1  && <div className={'ContentLoader preContentLoader'}>
            <CircularProgress color="inherit" size={20} />
            </div>}
       {!this.state.openCrewPopOver && <MDBRow className='bottomMargin'>
          <MDBCol md={12}>
            <BasicLabel text="Search Crew" isMandatory={true} />
            <SearchFieldWithAddValue
              id='crewFieldProject'
              searchSelect={true}
              disabled={this.props?.mode == 'edit' ? true : false}
              loading={this.state.isCrewFetch}
              detail_selected={this.state?.selectedCrew}
              options={this.state?.crewOptions || []}
              onChange={(e, value) => this.onChange('crew', value, e)}
              searchText={ev => {
                if (ev.target.value !== "" && ev.target.value !== null) {
                  this.getObjectDetails('Crew', ev.target.value);
                }
                else {
                  this.setState({ isCrewFetch: false })
                }
              }}
              newLabel={'Crew'}
              placeholder='-Search by Name or Occupation -'
            />
            <div className='errorText'>{this.state?.showError && !this.state?.selectedCrew ? 'Field Is Requird!!' : ''}</div>
          </MDBCol>
        </MDBRow>
  }
        {this.state?.selectedCrew && this.state.selectedCrewNew === null && (
          this.state?.isCrewDetailsFetching && this.state.isNewCrew!==1 ? 
          <div className={'ContentLoader'}>
            <CircularProgress color="inherit" size={20} />
            </div> :
           (!this.state.openCrewPopOver &&  <>           
              <MDBRow className='bottomMargin'>
                <MDBCol md={12}>
                  <BasicLabel text="Occupation" />
                  <SearchSelectField
                    id={"occupationFieldProject"}
                    options={this.state?.occupationOptions || []}
                    //={this.state?.crewDetails?.occupation_id ? this.state?.crewDetails?.occupation_id : null}
                    width={'100%'}
                    detail_selected={this.state?.selectedOccupation}
                    valueSelected={(e, value) => this.handleSelectedOccupation(value)}
                    searchText={(e) => this.getObjectDetails("Occupation", e.target.value)}
                    multiple={false}
                    searchSelect={true}
                    placeholder={"- Search Occupation -"}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className='bottomMargin'>
                <MDBCol md={12}>
                  <BasicTextField
                    id='locationName'
                    label="Location"
                    value={this.state?.crewDetails?.location_name}
                    onChange={(e) => this.onChangeField('location_name', e.target?.value)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className='bottomMargin'>
                <MDBCol md={12}>
                  <BasicTextField
                    id='companyName'
                    label="Company"
                    value={this.state?.crewDetails?.company ? this.state?.crewDetails?.company : ' '}
                    onChange={(e) => this.onChangeField('company', e.target?.value)}
                  />
                  {/* <BasicLabel text="Company" />
                <div className='companyDiv bottomMargin'>{this.state?.crewDetails?.company ? this.state?.crewDetails?.company : ' '}</div> */}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md={5}>
                  {/* {!this.props?.isEditing ? <BasicLabel text="Phone" /> : null} */}
                  {this.state?.crewDetails?.phone_list?.filter(i => i?.is_delete == 0)?.map((phone, index) => (
                    <MDBRow>
                      <MDBCol md={12}>
                        <PhoneComponent
                          radioGroupName='crewPhoneRadio'
                          placeholder = {" "}
                          showAsLabel={false}
                          isEditing={true}
                          objectItem={phone}
                          ext={true}
                          inValidInput={"Please enter valid phone"}
                          addCallback={(obj) => this.addRemoveObject('phone_list', obj)}
                          removeCallback={(id, key, value) => this.onChangeObject('phone_list', id, key, value)}
                          editCallback={(id, key, value) => this.onChangeObject('phone_list', id, key, value)}
                          selectOptions={this.state?.phoneOptions}
                          showAdd={(index === 0) ? true : false}
                          showRemove={index != 0 ? true : false}
                          showRadio={index != 0 ? true : false}
                          inputValidateMethod={validateAllPhone}
                          showLabel={index == 0 ? true : false}
                          labelNew={"Phone"}
                          addButton={index == 0 ? true : false}
                          originPoint={"newCrew"}
                          enableSecondaryError = {true}
                        />
                      </MDBCol>
                      {/* <MDBCol md={1}>
                        <div>
                          {phone?.is_primary && !this.props?.isEditing ? <MDBIcon fas icon="star" /> : null }
                        </div>
                      </MDBCol> */}
                    </MDBRow>
                  ))}
                </MDBCol>
                <MDBCol md={7}>
                  {this.state?.crewDetails?.email_list
                    ?.filter((i) => i?.is_delete == 0)
                    ?.map((item, index) => (
                      <InputSelectRadio
                        radioGroupName="crewEmailRadio"
                        inputPlaceholder=" "
                        showAsLabel={false}
                        isEditing={true}
                        objectItem={item}
                        addCallback={(emailObj) =>
                          this.addRemoveObject("email_list", emailObj)
                        }
                        removeCallback={(id, key, value) =>
                          this.onChangeObject(
                            "email_list",
                            id,
                            key,
                            value
                          )
                        }
                        editCallback={(id, key, value) =>
                          this.onChangeObject(
                            "email_list",
                            id,
                            key,
                            value
                          )
                        }
                        selectOptions={this.state.emailOptions}
                        showAdd={(index === 0) ? true : false}
                        showRemove={
                          index != 0 ? true : false
                        }
                        showRadio={
                          index != 0 ? true : false
                        }
                        inputValidateMethod={validateEmail}
                        showLabel={index == 0 ? true : false}
                        labelNew={"Email"}
                        addButton={index == 0 ? true : false}
                        enableSecondaryError = {true}
                      />
                    ))}
                </MDBCol>
              </MDBRow>
            </>)


        )}
        {!this.state.openCrewPopOver && this.state.isNewCrew!==1 &&
        <MDBRow className='d-flex justify-content-end topMargin'>
          <MDBCol md={2} className='d-flex justify-content-end'>
            <BasicButton
              text={this.state.isPosting ? <CircularProgress color="inherit" size={20} />
                : this.props?.primaryButtonText}
              icon="save"
              type="inline"
             // disabled={this.state.disablePhoneSave || this.state.disableEmailSave}
              onClick={() => this.handleSubmit()}
            />
          </MDBCol>
          <MDBCol md={2} className='d-flex justify-content-end'>
            <BasicButton
              variant="outlined"
              text="Cancel"
              icon="times"
              onClick={() => this.props?.onClose()}
            />
          </MDBCol>
        </MDBRow>
  }
        {this.state.setCrewPopOver ? <NewCrewModal
          isDashboard={true}
          id={'simple-popover'}
          open={this.state.setCrewPopOver}
          setPopOver={this.state.setCrewPopOver}
          handleClose={this.handleCloseCrewPopOver.bind(this)}
          crewData={this.state?.selectedCrew}
          getObjectDetails={this.getObjectDetails.bind(this)}
          setObjectSelected={this.setObjectSelected.bind(this)}
          setNewObjectSelected={this.setNewObjectSelected.bind(this)}
          modalName={"New Crew"}
        /> : null}

        {this.state.showCrewModal ?
          <ModalComponent
            id={this.state.selectedCrew?.value}
            akas={this.state?.selectedCrew?.aka}
            open={this.state.showCrewModal}
            handleClose={() => this.setState({ showCrewModal: false }, () => this.getCrewDetails("calledfromClose"))}
            tabList={crewNavList}
            headerText={"Crew"}
            mode="edit"
            isProfileDiv={true}
            addButtonText={"New Crew"}
            objectType={'CREW'}
            objectId={this.state?.selectedCrew?.value}
            dataUrl='casterFeatureCrewDetails'
            headerName={this.state.selectedCrew?.headerName}
            fetchDetailsCallback={this.getCrewDetails.bind(this)}
          /> : null
        }
      </MDBContainer>
    )
  }
}